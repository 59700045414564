<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()">
          <i class="el-icon-error"></i>
        </span>
        <!-- vue-cropper与element ui实现头像图片剪切上传   未做 -->
        <div class="wb-tit font18">修改头像</div>
        <div class="c-main">
          <div class="touxiangno">
            <div class="cropper-wrap" v-if="option.img">
              <div class="cropper-box">
                <vueCropper
                  ref="cropper"
                  :img="option.img"
                  :output-size="option.size"
                  :output-type="option.outputType"
                  :info="option.info"
                  :full="option.full"
                  :canScale="option.canScale"
                  :can-move="option.canMove"
                  :can-move-box="option.canMoveBox"
                  :fixed="option.fixed"
                  :fixed-box="option.fixedBox"
                  :original="option.original"
                  :auto-crop="option.autoCrop"
                  :auto-crop-width="option.autoCropWidth"
                  :auto-crop-height="option.autoCropHeight"
                  :center-box="option.centerBox"
                  :high="option.high"
                  :info-true="option.infoTrue"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :maxImgSize="option.maxImgSize"
                  @realTime="realTime"
                ></vueCropper>
              </div>
              <div class="preview-box">
                <div :style="previewStyle" class="preview-img">
                  <div>
                    <el-upload
                      class="avatar-uploader"
                      action="https://freemen.work/user/file/upload/"
                      name="file"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img :src="preview.url" :style="preview.img" />
                    </el-upload>
                  </div>
                </div>
              </div>
            </div>
            <el-upload
              v-else
              class="avatar-uploader"
              action="https://freemen.work/user/file/upload/"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="imageUrl"
                :src="'https://freemen.work/user/file/view/' + imageUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div class="el-upload__tip font14" slot="tip">
                只能上传jpg/png格式文件，文件不能超过3M
              </div>
            </el-upload>
          </div>
          <div class="btn">
            <p v-if="option.img">点击右侧图片可重新上传</p>
            <el-button type="primary" @click="submitForm">确定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";

export default {
  data() {
    return {
      cropperData: {},
      dialogVisible: false,
      imageUrl: "",
      callback: null,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 150, // 默认生成截图框宽度
        autoCropHeight: 150, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        canMove: true,
        fileinfo: {},
      },
      newImgUrl: "",
      fileName: "",
      preview: {},
      previewStyle: {},
    };
  },
  mounted() {
    Bus.$on("modifyavatarstuts", (data) => {
      this.dialogVisible = data.show;
      this.callback = data.callback;
    });
  },
  watch: {
    cropperOption: {
      handler(value) {
        this.option = Object.assign(this.option, value);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    realTime(preview) {
      // 实时预览
      // console.log("realTime-------, preview = ", preview);
      this.preview = preview;
      this.preview.div.width = 176 + "px";
      this.preview.div.height = 176 + "px";

      // this.preview.img.marginLeft = "-"+ this.preview.img.transform.split('(')[2].split(')')[0].split(',')[0];
      // this.preview.img.marginTop = "-"+ this.preview.img.transform.split('(')[2].split(')')[0].split(',')[1].split(' ')[1];
      this.preview.img.width = 176 + "px";
      this.preview.img.height = 176 + "px";
      // this.preview.img.transform = "scale(1)translate3d(48px, 52px, 0px)rotateZ(0deg)"
      this.previewStyle = {
        width: 176 + "px",
        height: 176 + "px",
        overflow: "hidden",
        margin: "0",
        zoom: this.zoom,
      };
      // console.log("realTime-------, this.preview = ", this.preview);
    },
    // 将base64转换为文件
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let len = bstr.length;
      let u8arr = new Uint8Array(len);
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len);
      }
      return new File([u8arr], filename, { type: mime });
    },
    // 将base64转换为png文件图片
    // finish() {
    //   this.$refs.cropper.getCropData((data) => {
    //     let file = this.dataURLtoFile(data, "images.png");
    //     this.$emit("uploadCropper", file, data);
    //   });
    // },

    // 自定义上传方法
    uploadImg(file, data) {
      let fileFormData = new FormData();
      fileFormData.append("file", file);
      // 移除上传组件带来的bug
      // document.getElementsByTagName('body')[0].removeAttribute('style')
      this.cropperData.iconUrl = data;
      this.showCropper = false;
      // api.uploadFile(fileFormData, this).then(res => {
      //   this.cropperData.iconUrl = res
      //   this.showCropper = false
      //   this.$message({
      //     message: '操作成功',
      //     type: 'success'
      //   })
      // })
    },
    closebox() {
      this.dialogVisible = false;
      Bus.$emit("modifyavatarstuts", { show: false });
    },
    handleAvatarSuccess(response, file) {
      // console.log("----handleAvatarSuccess-----", response, file);
      this.imageUrl = response.data;
      this.imageUrl = response.data;
      this.fileName = file.name;
      this.option.img = URL.createObjectURL(file.raw);
      // console.log("----handleAvatarSuccess----- this.option = ", this.option);
    },
    submitForm() {
      this.finish();
    },
    finish() {
      const formData = new FormData();

      this.$refs.cropper.getCropBlob((data) => {
        // let file = new File([data], this.fileName, {type: this.fileName.split('.')[1]});
        // data转换为bolb之后是没有name的,后段接收到file之后需要获取到文件的名称；
        data.name = this.fileName;
        // console.log("data", data);
        formData.append("file", data, this.fileName);
        this.$api.upload("post", formData).then((res) => {
          this.callback(res.data);
          this.closebox();
          this.option.img = "";
        });
        // this.newImgUrl = res.Data;
      });
    },

    beforeAvatarUpload(file) {
      // console.log("--beforeAvatarUpload---", file);
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
      	this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
      	this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
.avatar-uploader {
  text-align: center;
}
.avatar-uploader .el-upload {
  /* border: 1px dashed #d9d9d9; */
  /* border-radius: 50px; */
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #eeeeee;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 30px;
  color: #999999;
  width: 92px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.avatar {
  width: 92px;
  height: 106px;
  display: block;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  .wb-tit {
    padding: 17px 0 17px 30px;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    // border-bottom: 1px solid #DDDDDD;
  }
}

.c-main {
  padding: 50px 0;
  .touxiangno {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-upload__tip {
      text-align: center;
      color: #999999;
    }
  }
  .btn {
    margin-top: 50px;
    text-align: center;
    /deep/ .el-button {
      border-radius: 0px;
      width: 500px;

      background: #00bcff;
      border: none;
    }
  }
}
</style>


<style lang="less" scoped>
.cropper-dialog {
  & /deep/ .el-dialog {
    width: max-content;
  }
  & /deep/ .el-dialog__body {
    padding: 20px;
  }
  & /deep/ .el-button {
    width: 145px;
  }
}
.cropper-wrap {
  display: flex;
  .cropper-box {
    margin-right: 20px;
    width: 375px;
    height: 176px;
  }
  .preview-box {
    .preview-title {
      display: flex;
      min-width: 100px;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      color: rgba(30, 35, 48, 1);
      font-family: PingFangSC-Medium;
      .preveiw-upload {
        color: #0067ed;
        cursor: pointer;
      }
    }
    .preview-img {
      border-radius: 2px;
    }
  }
}
.fun-btn {
  //margin-top: 16px;
  i {
    //margin-right: 16px;
    font-size: 18px;
    color: #8c8c8c;
    cursor: pointer;
    &:hover {
      color: #0067ed;
    }
  }
  .reUpload {
    //margin-right: 16px;
  }
}
</style>
